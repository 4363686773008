<template>
    <div class="table-container">
        <button @click="addParty">Add Party</button>
        <button @click="exportToCsv">Export to CSV</button>
        <table>
            <thead>
                <tr>
                    <th>Party Name</th>
                    <th>Attendees</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr :class="{ editing: party.editable }" v-for="(party, index) in parties" :key="index">
                    <td v-if="!party.editable">{{ party.partyName }}</td>
                    <td v-if="!party.editable">{{ party.attendees }}</td>
                    <td>
                        <button class="btn-edit" v-if="!party.editable" @click="editParty(party)">Edit</button>
                        <button class="btn-edit" v-if="party.editable" @click="saveParty(party)">Save</button>
                        <button class="btn-edit" v-if="party.editable" @click="cancelParty(party, index)">Cancel</button>
                        <button @click="deleteParty(party, index)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Party-Modal v-if="showModal"
                     @close="showModal=false"
                     @save="saveParty"
                     :party="selectedParty">
        </Party-Modal>
    </div>
</template>

<script>
    import axios from "axios";
    import qs from 'qs';
    import PartyModal from "../../components/Management/PartyModal.vue";
    import { guidv4, encodeParty, decodeParty, decodeAttendee } from "../../components/Utility/Utility.js";

    export default {
         components: {
            PartyModal
        },
        data() {
            return {
                tab: null,
                showModal: false,
                parties: [
                ],
                selectedParty: {
                    id: "",
                    partyName: "",
                    isNew: false
                },
                formData: {
                    action: "",
                    token: ""
                },
            };
        },
        mounted() {
            var _jwt = localStorage.getItem('jwtToken');
            if (_jwt === null) {
                this.$router.push('Login');
            }
            this.getParties();
        },
        methods: {
            editParty(party) {
                this.formData.action = 'getPartyDetails';
                this.formData.token = localStorage.getItem('jwtToken');
                encodeParty(party);
                this.formData.data = JSON.stringify(party);
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        decodeParty(party);
                        this.selectedParty = response.data;
                        this.selectedParty.isNew = false;
                        console.log(this.selectedParty);
                        decodeParty(this.selectedParty);
                        this.showModal = true;
                    })
                    .catch(error => {
                        console.error(error);
                });        
            },
            saveParty() {
                this.formData.action = 'saveParty';
                this.formData.token = localStorage.getItem('jwtToken');
                encodeParty(this.selectedParty);
                this.formData.data = JSON.stringify(this.selectedParty);
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        this.selectedParty.editable = false;
                        this.selectedParty.isNew = false;
                        decodeParty(this.selectedParty);
                        this.showModal = false;
                        this.getParties();
                    })
                    .catch(error => {
                        console.error(error);
                });
            },
            getParties() {
                this.formData.action = 'getParties';
                this.formData.token = localStorage.getItem('jwtToken');
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        this.parties = response.data.parties;

                        for (var i = 0; i < this.parties.length; i++) {
                            decodeParty(this.parties[i]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            deleteParty(party, index) {
                this.formData.action = 'deleteParty';
                this.formData.token = localStorage.getItem('jwtToken');
                encodeParty(party);
                this.formData.data = JSON.stringify(party);
                if (!party.isNew) {
                    axios({
                        method: 'POST',
                        url: 'https://api.jessplusjosh.com',
                        data: qs.stringify(this.formData)
                    })
                        .then(response => {
                             this.getParties();
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    this.parties.splice(index, 1);
                }
            },
            addParty() {
                this.formData.action = 'getAttendees';
                this.formData.token = localStorage.getItem('jwtToken');
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        this.selectedParty.id =  guidv4();
                        this.selectedParty.isNew = true;
                        this.selectedParty.partyName = "New Party";
                        this.selectedParty.attendeesIn = [];
                        this.selectedParty.attendeesOut = response.data.attendees;

                        // encode attendeeIn
                        for (var i = 0; i < this.selectedParty.attendeesOut.length; i++) {
                            decodeAttendee(this.selectedParty.attendeesOut[i]);
                        };

                        this.showModal = true;
                    })
                    .catch(error => {
                        console.error(error);
                });    
            },
             exportToCsv() {
                 // Create a CSV string from the guest list
                  let csv = 'Id,Name,Members,Url\n'
                 this.parties.forEach((party) => {
                     csv += `${party.id},"${party.partyName}","${party.attendees}",${window.location.origin}?party=${party.id}\n`
                  })

                  // Download the CSV file
                  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
                  const url = URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.setAttribute('href', url)
                  link.setAttribute('download', 'party_list.csv')
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
            }
        }
    };
</script>

<style lang="scss">
    .feather-menu {
        color: #1d1d24;
    }

    .table-container {
        margin: 20px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #333;
    }

    th,
    td {
        text-align: left;
        padding: 10px;
    }

    th {
        background-color: #f1f1f1;
        color: #555;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    td input[type="text"],
    td input[type="checkbox"],
    td select {
        border: none;
        padding: 5px;
        width: 100%;
    }

    td.editing input[type="text"],
    td.editing select {
        background-color: #e8f0fe;
    }

    td.editing input[type="checkbox"] {
        opacity: 0.7;
    }

    tr.editing {
        background-color: #e8f0fe;
    }

    button {
        background-color: #4caf50;
        border: none;
        color: white;
        padding: 10px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 5px;
        cursor: pointer;
    }

        button.delete {
            background-color: #f44336;
        }

        button.edit {
            background-color: #2196f3;
        }

        button.cancel {
            background-color: #f1c40f;
            color: #333;
        }

        button.insert {
            background-color: #9b59b6;
        }

        button.disabled {
            opacity: 0.5;
            cursor: default;
        }

        button:hover {
            opacity: 0.8;
        }

    .edittd select {
        box-shadow: 0 0 1px #1d1d24;
    }

    .edittd input {
        box-shadow: 0 0 1px #1d1d24;
    }

    @media (max-width: 600px) {
        table {
            font-size: 12px;
        }

        button {
            font-size: 12px;
            padding: 8px 12px;
            margin: 3px;
        }
    }
</style>
