<template>
    <div>
        <!-- Modal for adding new customers -->
        <div class="modal-background">
            <div class="modal-content">
                <input class="super-center" v-model="party.partyName">
                <div class="flick-selects">
                    <div class="select-container">
                        <select v-model="selectedOption1" size="10">
                            <option v-for="attendee in party.attendeesIn" :value="attendee.id">{{ attendee.name }}</option>
                        </select>
                    </div>
                    <div class="arrows-container">
                        <button @click="flickOptionsToRight">&lt;</button>
                        <button @click="flickOptionsToLeft">&gt;</button>
                    </div>
                    <div class="select-container">
                        <select v-model="selectedOption2" size="10">
                            <option v-for="attendee in party.attendeesOut" :value="attendee.id">{{ attendee.name }}</option>
                        </select>
                    </div>
                </div>
                <button @click="saveModal">Save</button>
                <button @click="closeModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
          props: {
            title: {
              type: String,
              default: 'Modal Title'
            },
            message: {
              type: String,
              default: 'Modal Message'
            },
            party: {
                type: Object
            }
          },
        data() {
            return {
                showModal: false,
                selectedOption1: 'Option 1a',
                selectedOption2: 'Option 2a',
            };
        },
        methods: {
            flickOptionsToLeft() {
                for (var i = 0; i < this.party.attendeesIn.length; i++) {
                    if (this.party.attendeesIn[i].id === this.selectedOption1) {
                        const moveItem = this.party.attendeesIn[i];
                        this.party.attendeesIn.splice(i, 1);
                        this.party.attendeesOut.unshift(moveItem);
                        this.selectedOption1 = this.party.attendeesIn[0];
                        this.selectedOption2 = this.party.attendeesOut[0];
                    }
                }
            },
            flickOptionsToRight() {
                for (var i = 0; i < this.party.attendeesOut.length; i++) {
                    if (this.party.attendeesOut[i].id === this.selectedOption2) {
                        const moveItem = this.party.attendeesOut[i];
                        this.party.attendeesOut.splice(i, 1);
                        this.party.attendeesIn.unshift(moveItem);
                        this.selectedOption1 = this.party.attendeesIn[0];
                        this.selectedOption2 = this.party.attendeesOut[0];
                    }
                }
            },
        closeModal() {
              this.$emit('close');
            },
        saveModal() {
              this.$emit('save');
            }
        },
    };
</script>

<style>
    .flick-selects {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .select-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2rem;
    }

    .arrows-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    button {
        font-size: 1.5rem;
        margin: 0.5rem;
    }

   select {
      width: 10rem !important;
      height: 20rem !important;
    }

   .super-center {
       text-align: center !important;
       background-color: #e8f0fe;
        box-shadow: 0 0 1px #1d1d24;
   }

   .modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}
</style>