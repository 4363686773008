<template>
    <v-col lg="12">
        <div class="tab-default">
            <v-tabs v-model="tab" flat hide-slider>
                <v-tab key="invitee" :ripple="false">
                    Invitees
                </v-tab>
                <v-tab key="party" :ripple="false">
                    Party
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition"
                            key="invitee">
                    <v-card flat>
                        <v-card-text>
                            <Attendees></Attendees>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item transition="fade-transition"
                            key="party">
                    <v-card flat>
                        <v-card-text>
                            <Parties></Parties>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-col>
</template>

<script>
    import { ValidationObserver } from "vee-validate";
    import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
    import Attendees from "../../components/Management/Attendees.vue";
    import Parties from "../../components/Management/Parties.vue";
    import axios from "axios";
    import qs from 'qs';

    export default {
         components: {
            Attendees,
            Parties
        },
        data() {
            return {
                tab: null,
                attendees: [
                ],
                edittedAttendee: {
                        id: "",
                        name: "",
                        isAttending: null,
                        dietaryRequirements: "",
                        song: "",
                        transportationRequirement: "",
                        editable: false,
                        isNew: false
                },
                formData: {
                    action: "",
                    token: ""
              },
            };
        },
        mounted() {
            var _jwt = localStorage.getItem('jwtToken');
            if (_jwt === null) {
                this.$router.push('Login');
            }
            this.getAttendees();
        },
        methods: {
            editAttendee(attendee) {
                attendee.editable = true;
                this.edittedAttendee =  {
                        id: attendee.id,
                        name: attendee.name,
                        isAttending: attendee.isAttending,
                        dietaryRequirements: attendee.dietaryRequirements,
                        song: attendee.song,
                        transportationRequirement: attendee.transportationRequirement,
                        isNew: attendee.isNew
                }
            },
            saveAttendee(attendee) {
                this.formData.action = 'saveAttendee';
                this.formData.token = localStorage.getItem('jwtToken');
                this.formData.data = JSON.stringify(attendee);
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        attendee.editable = false;
                        attendee.isNew = false;
                        console.log(response);
                })
                    .catch(error => {
                      console.error(error);
                });
            },
            getAttendees() {
                this.formData.action = 'getAttendees';
                this.formData.token = localStorage.getItem('jwtToken');
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        this.attendees = response.data.attendees;
                        console.log(response);
                    //this.token = response.data.token
                    //if (response.data.token !== undefined) {
                    //    localStorage.setItem('jwtToken', response.data.token);
                    //    this.$router.push('Management');
                    //} else {
                    //    this.$refs.password.applyResult({
                    //        errors: ["Unable to login, please check your username and password"],
                    //        valid: false,
                    //        failedRules: {}
                    //    });
                    //}

                })
                    .catch(error => {
                      console.error(error);
                });
            },
            cancelEdit(attendee, index) {
                attendee.editable = false;
                attendee.name = this.edittedAttendee.name;
                attendee.isAttending = this.edittedAttendee.isAttending;
                attendee.dietaryRequirements = this.edittedAttendee.dietaryRequirements;
                attendee.song = this.edittedAttendee.song;
                attendee.transportationRequirement = this.edittedAttendee.transportationRequirement;

                if (attendee.isNew) {
                    this.deleteAttendee(attendee, index);
                }
            },
            deleteAttendee(attendee, index) {
                this.formData.action = 'deleteAttendee';
                this.formData.token = localStorage.getItem('jwtToken');
                this.formData.data = JSON.stringify(attendee);
                if (!attendee.isNew) {
                    axios({
                        method: 'POST',
                        url: 'https://api.jessplusjosh.com',
                        data: qs.stringify(this.formData)
                    })
                        .then(response => {
                            this.attendees.splice(index, 1);
                            console.log(response);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                     this.attendees.splice(index, 1);
                }
            },
            addAttendee() {
                for (var i = 0; i < this.attendees.length; i ++) {
                    if (this.attendees[i].editable) {
                        this.cancelEdit(this.attendees[i]);
                    }
                }
                var _guid = this.guidv4()

                this.attendees.unshift({
                    id: _guid,
                    name: "",
                    isAttending: null,
                    dietaryRequirements: "",
                    song: "",
                    transportationRequirement: "",
                    editable: false,
                    isNew: true
                });

                for (var i = 0; i < this.attendees.length; i ++) {
                    if (this.attendees[i].id === _guid) {
                        this.editAttendee(this.attendees[i]);
                    }
                }

            },
            guidv4(data = null) {
              // Generate 16 bytes (128 bits) of random data or use the data passed into the function.
              if (!data) {
                data = new Uint8Array(16);
                window.crypto.getRandomValues(data);
              }

              // Set version to 0100
              data[6] = data[6] & 0x0f | 0x40;
              // Set bits 6-7 to 10
              data[8] = data[8] & 0x3f | 0x80;

              // Convert to hexadecimal string
              const hex = Array.from(data)
                .map(b => b.toString(16).padStart(2, '0'))
                .join('');

              // Output the 36 character UUID.
              return hex.substring(0, 8) + '-' +
                hex.substring(8, 12) + '-' +
                hex.substring(12, 16) + '-' +
                hex.substring(16, 20) + '-' +
                hex.substring(20);
            }
        }
    };
</script>

<style lang="scss">
    .feather-menu {
        color: #1d1d24;
    }

    .table-container {
        margin: 20px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #333;
    }

    th,
    td {
        text-align: left;
        padding: 10px;
    }

    th {
        background-color: #f1f1f1;
        color: #555;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    td input[type="text"],
    td input[type="checkbox"],
    td select {
        border: none;
        padding: 5px;
        width: 100%;
    }

    td.editing input[type="text"],
    td.editing select {
        background-color: #e8f0fe;
    }

    td.editing input[type="checkbox"] {
        opacity: 0.7;
    }

    tr.editing {
        background-color: #e8f0fe;
    }

    button {
        background-color: #4caf50;
        border: none;
        color: white;
        padding: 10px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 5px;
        cursor: pointer;
    }

        button.delete {
            background-color: #f44336;
        }

        button.edit {
            background-color: #2196f3;
        }

        button.cancel {
            background-color: #f1c40f;
            color: #333;
        }

        button.insert {
            background-color: #9b59b6;
        }

        button.disabled {
            opacity: 0.5;
            cursor: default;
        }

        button:hover {
            opacity: 0.8;
        }

        .edittd select {
          box-shadow: 0 0 1px #1d1d24;
        }
        
        .edittd input {
          box-shadow: 0 0 1px #1d1d24;
        }

    @media (max-width: 600px) {
        table {
            font-size: 12px;
        }

        button {
            font-size: 12px;
            padding: 8px 12px;
            margin: 3px;
        }
    }
</style>
