<template>
    <div class="table-container">
        <button @click="addAttendee">Add Attendee</button>
        <button @click="exportToCsv">Export to CSV</button>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Is Attending</th>
                    <th>Dietary Requirements</th>
                    <th>Song</th>
                    <th>Transportation Requirement</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr :class="{ editing: attendee.editable }" v-for="(attendee, index) in attendees" :key="index">
                    <td v-if="!attendee.editable">{{ attendee.name }}</td>
                    <td class="edittd" v-else><input v-model="attendee.name"></td>
                    <td v-if="!attendee.editable">{{ attendee.isAttending === null ? "Unknown" : attendee.isAttending ? "Yes" : "No" }}</td>
                    <td class="edittd" v-else>
                        <select v-model="attendee.isAttending">
                            <option :value="null">Unknown</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </td>
                    <td v-if="!attendee.editable">{{ attendee.dietaryRequirements }}</td>
                    <td class="edittd" v-else><input v-model="attendee.dietaryRequirements"></td>
                    <td v-if="!attendee.editable">{{ attendee.song }}</td>
                    <td class="edittd" v-else><input v-model="attendee.song"></td>
                    <td v-if="!attendee.editable">{{ attendee.transportationRequirement }}</td>
                    <td class="edittd" v-else><input v-model="attendee.transportationRequirement"></td>
                    <td>
                        <button class="btn-edit" v-if="!attendee.editable" @click="editAttendee(attendee)">Edit</button>
                        <button class="btn-edit" v-if="attendee.editable" @click="saveAttendee(attendee)">Save</button>
                        <button class="btn-edit" v-if="attendee.editable" @click="cancelEdit(attendee, index)">Cancel</button>
                        <button @click="deleteAttendee(attendee, index)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
    import axios from "axios";
    import qs from 'qs';
    import { guidv4, decodeAttendee, encodeAttendee } from "../../components/Utility/Utility.js";

    export default {
        data() {
            return {
                tab: null,
                attendees: [
                ],
                edittedAttendee: {
                    id: "",
                    name: "",
                    isAttending: null,
                    dietaryRequirements: "",
                    song: "",
                    transportationRequirement: "",
                    editable: false,
                    isNew: false
                },
                formData: {
                    action: "",
                    token: ""
                },
            };
        },
        mounted() {
            var _jwt = localStorage.getItem('jwtToken');
            if (_jwt === null) {
                this.$router.push('Login');
            }
            this.getAttendees();
        },
        methods: {
            editAttendee(attendee) {
                attendee.editable = true;
                this.edittedAttendee = {
                    id: attendee.id,
                    name: attendee.name,
                    isAttending: attendee.isAttending,
                    dietaryRequirements: attendee.dietaryRequirements,
                    song: attendee.song,
                    transportationRequirement: attendee.transportationRequirement,
                    isNew: attendee.isNew
                }
            },
            saveAttendee(attendee) {
                this.formData.action = 'saveAttendee';
                this.formData.token = localStorage.getItem('jwtToken');
                encodeAttendee(attendee);
                this.formData.data = JSON.stringify(attendee);
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        decodeAttendee(attendee);
                        attendee.editable = false;
                        attendee.isNew = false;
                        console.log(response);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getAttendees() {
                this.formData.action = 'getAttendees';
                this.formData.token = localStorage.getItem('jwtToken');
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        this.attendees = response.data.attendees;
                         for (var i = 0; i < this.attendees.length; i++) {
                            decodeAttendee(this.attendees[i]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                });
            },
            cancelEdit(attendee, index) {
                attendee.editable = false;
                attendee.name = this.edittedAttendee.name;
                attendee.isAttending = this.edittedAttendee.isAttending;
                attendee.dietaryRequirements = this.edittedAttendee.dietaryRequirements;
                attendee.song = this.edittedAttendee.song;
                attendee.transportationRequirement = this.edittedAttendee.transportationRequirement;

                if (attendee.isNew) {
                    this.deleteAttendee(attendee, index);
                }
            },
            deleteAttendee(attendee, index) {
                this.formData.action = 'deleteAttendee';
                this.formData.token = localStorage.getItem('jwtToken');
                encodeAttendee(attendee);
                this.formData.data = JSON.stringify(attendee);
                if (!attendee.isNew) {
                    axios({
                        method: 'POST',
                        url: 'https://api.jessplusjosh.com',
                        data: qs.stringify(this.formData)
                    })
                        .then(response => {
                            this.attendees.splice(index, 1);
                            console.log(response);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    this.attendees.splice(index, 1);
                }
            },
            addAttendee() {
                for (var i = 0; i < this.attendees.length; i++) {
                    if (this.attendees[i].editable) {
                        this.cancelEdit(this.attendees[i]);
                    }
                }
                var _guid = guidv4()

                this.attendees.unshift({
                    id: _guid,
                    name: "",
                    isAttending: null,
                    dietaryRequirements: "",
                    song: "",
                    transportationRequirement: "",
                    editable: false,
                    isNew: true
                });

                for (var i = 0; i < this.attendees.length; i++) {
                    if (this.attendees[i].id === _guid) {
                        this.editAttendee(this.attendees[i]);
                    }
                }

            },
            exportToCsv() {
                  // Create a CSV string from the guest list
                  let csv = 'Id,Name,Dietary Requirements,Song Request,Is Attending,Transportation Requirements\n'
                  this.attendees.forEach((attendee) => {
                    csv += `${attendee.id},"${attendee.name}","${attendee.dietaryRequirements}","${attendee.song}",${attendee.isAttending},${attendee.transportationRequirement}\n`
                  })

                  // Download the CSV file
                  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
                  const url = URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.setAttribute('href', url)
                  link.setAttribute('download', 'attendee_list.csv')
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
            }
        }
    };
</script>

<style lang="scss">
    .feather-menu {
        color: #1d1d24;
    }

    .table-container {
        margin: 20px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #333;
    }

    th,
    td {
        text-align: left;
        padding: 10px;
    }

    th {
        background-color: #f1f1f1;
        color: #555;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    td input[type="text"],
    td input[type="checkbox"],
    td select {
        border: none;
        padding: 5px;
        width: 100%;
    }

    td.editing input[type="text"],
    td.editing select {
        background-color: #e8f0fe;
    }

    td.editing input[type="checkbox"] {
        opacity: 0.7;
    }

    tr.editing {
        background-color: #e8f0fe;
    }

    button {
        background-color: #4caf50;
        border: none;
        color: white;
        padding: 10px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 5px;
        cursor: pointer;
    }

        button.delete {
            background-color: #f44336;
        }

        button.edit {
            background-color: #2196f3;
        }

        button.cancel {
            background-color: #f1c40f;
            color: #333;
        }

        button.insert {
            background-color: #9b59b6;
        }

        button.disabled {
            opacity: 0.5;
            cursor: default;
        }

        button:hover {
            opacity: 0.8;
        }

    .edittd select {
        box-shadow: 0 0 1px #1d1d24;
    }

    .edittd input {
        box-shadow: 0 0 1px #1d1d24;
    }

    @media (max-width: 600px) {
        table {
            font-size: 12px;
        }

        button {
            font-size: 12px;
            padding: 8px 12px;
            margin: 3px;
        }
    }
</style>
